<template>
  <surround-box>
    <div class="content-box">
      <div class="pane-item">
        <img src="../../assets/imgs/title_1.png" />
        <div class="pane-right">
          <span class="pane-right-title">生猪养殖规模(头)</span>
          <span>{{pigNum}}</span>
        </div>
        <!-- <div class="svga" ref="pig" style="width: 52px; height: 52px"></div> -->
      </div>
      <div class="pane-item">
        <img src="../../assets/imgs/title_2.png" />
        <div class="pane-right">
          <span class="pane-right-title">消纳土地规模(亩)</span>
          <span>{{xiaonaNum}}</span>
        </div>
        <!-- <div class="svga" ref="area" style="width: 52px; height: 52px"></div> -->
      </div>
      <div class="pane-item">
        <img src="../../assets/imgs/title_3.png" />
        <div class="pane-right">
          <span class="pane-right-title">资源化利用比例(%)</span>
          <span>{{ziyuanhuaNum}}</span>
        </div>
        <!-- <div class="svga" ref="circle" style="width: 52px; height: 52px"></div> -->
      </div>
    </div>
  </surround-box>
</template>
<script>
import surroundBox from '@/components/mapComp/surroundBox'
import * as SVGA from 'svgaplayerweb'

export default {
  components: { surroundBox },
  data() {
    return {
      // 生猪养殖规模
      pigNum: '',
      // 消纳土地规模
      xiaonaNum: '',
      // 资源化利用比例
      ziyuanhuaNum: '',
    }
  },
  methods: {
    initSvga(dom, url) {
      let player = new SVGA.Player(dom)
      let parser = new SVGA.Parser(dom)
      parser.load(url, (videoItem) => {
        player.setVideoItem(videoItem)
        player.startAnimation()
      })
    },
    getStatics() {
      this.$post(this.$api.STATICS_MANAGE.GET2, {
        companyNo: 'administrator',
        resourceTypeId: 24,
      }).then((res) => {
        res.forEach((element) => {
          if (element.dimensionName == '生猪养殖规模') {
            this.pigNum = element.content
          }
          if (element.dimensionName == '消纳土地规模') {
            this.xiaonaNum = element.content
          }
          if (element.dimensionName == '资源化利用比例') {
            this.ziyuanhuaNum = element.content
          }
        })
        // this.data = res;
        // this.initChart();
        // this.unit = res[0].contentUnit;
      })
    },
  },
  mounted() {
    // 猪
    this.initSvga(this.$refs.pig, '/svga/pig')
    // 土地
    this.initSvga(this.$refs.area, '/svga/area')
    //
    this.initSvga(this.$refs.circle, '/svga/circle')
    this.getStatics()
  },
}
</script>
<style lang='less' scoped>
/deep/ .left-top,
/deep/ .left-bottom,
/deep/ .right-top,
/deep/ .right-bottom {
  display: none;
}
.content-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pane-item {
    height: 1.06rem;
    margin: 0 0.1rem;
    position: relative;
    transform: scale(0.82);
    display: flex;
    justify-content: center;
    align-items: center;
    .svga {
      position: absolute;
      right: 0.25rem;
      bottom: 1.5vh;
    }
    > img {
      width: 1.425rem;
      height: 1.425rem;
    }
    .pane-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 0.3rem;
      text-align: center;
      > span:first-child {
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: #fff;
        background: url('../../assets/imgs/text_bg.png') no-repeat center;
        background-size: 100%;
      }
      > span:last-child {
        line-height: 5vh;
        font-size: 28px;
        font-weight: bold;
        text-align: left;
        background-image: linear-gradient(180deg, #02e9fd 2%, #1792fe 100%);
        color: transparent;
        background-clip: text;
      }
    }
  }
}
</style>
